export default [
    {
        title: 'menu.monte.contracts',
        icon: ['fal', 'file-contract'],
        accessRights: 'CASAILL',
        to: { name: 'ContractList' },
        mobile: true,
        desktop: true,
        home: true
    },
]
