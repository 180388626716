export default [
	{
		title: 'menu.actes.actes',
		icon: ['fal', 'tasks'],
		to: { name: 'acteListe' },
		accessRights: 'CAACTE',
        mobile: true,
        desktop: true,
        home: true
	}
]
