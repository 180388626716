export default [
	// {
	// 	title: 'menu.facturation.facturation',
	// 	icon: ['fal', 'user-cog'],
	// 	accessRights: 'MVP',
	// 	children: [
			// {
			// 	title: 'menu.facturation.mes_factures',
			// 	icon: ['fal', 'file-invoice'],
			// 	accessRights: 'CAFACT',
			// 	to: { name: 'addressedInvoices' }
			// },
			// {
			// 	title: 'menu.facturation.mes_reglements',
			// 	icon: ['fal', 'receipt'],
			// 	accessRights: 'CAREGL',
			// 	to: { name: 'addressedPayments' }
			// },
			// {
			// 	title: 'menu.facturation.mes_methodes_paiement',
			// 	icon: ['fal', 'money-check-edit-alt'],
			// 	accessRights: 'CAREGL&MANG',
			// 	to: { name: 'paymentMethodList' }
			// }
	// 	]
	// },
	{
		title: 'menu.facturation.mes_factures',
		icon: ['fal', 'file-invoice'],
		accessRights: 'CAFACT',
		to: { name: 'addressedInvoices' },
        mobile: true,
        desktop: true,
        home: true
	},
	{
		title: 'menu.facturation.mes_methodes_paiement',
		icon: ['fal', 'money-check-edit-alt'],
		accessRights: 'CAREGL&MANG',
		to: { name: 'paymentMethodList' },
        mobile: true,
        desktop: true,
        home: true
	},
	{
		title: 'menu.facturation.mes_methodes_paiement',
		icon: ['fal', 'money-check-edit-alt'],
		accessRights: 'CAREGL&GOCAR',
		to: { name: 'gocardlessPayment' },
        mobile: true,
        desktop: true,
        home: true
	},
    {
        title: 'menu.facturation.devis',
        accessRights: 'CADEVIS',
        icon: ['far', 'signature'],
        to: { name: 'addressedQuotations' },
        mobile: true,
        desktop: true,
        home: true
    },
]
