export default [
    {
        title: 'menu.accueil',
        icon: ['fal', 'home'],
        to: { name: 'HomeMenu' },
        accessRights: 'MVP',
        desktop: true
    },
    {
        title: 'menu.chevaux.chevaux',
        icon: ['fal', 'horse'],
        to: { name: 'horseListe' },
        accessRights: 'CAHRS',
        mobile: true,
        desktop: true,
        home: true
    },
    {
        title: 'menu.chevaux.juments',
        icon: ['cf', 'mare'],
        to: { name: 'mareListe' },
        accessRights: 'CAMARE',
        mobile: true,
        desktop: true,
        home: true
    },
    {
        title: 'global.pax',
        icon: ['cf', 'pax'],
        to: { name: 'paxHome' },
        accessRights: 'CAPAX',
        desktop: true
    },
    {
        title: 'global.pax_entier',
        icon: ['cf', 'pax'],
        to: { name: 'paxHome' },
        accessRights: 'CAPAX',
        mobile: true
    },
    {
        title: 'global.effectif',
        icon: ['fal', 'horse'],
        to: { name: 'effectif' },
        accessRights: 'CALE',
        mobile: true,
        desktop: true,
        home: true
    },
    {
        title: 'global.notes',
        icon: ['fal', 'tasks'],
        to: { name: 'notes' },
        accessRights: 'CANOTE',
        mobile: true,
        desktop: true,
        home: true
    },
    {
        title: 'global.feed',
        icon: ['fad', 'comments-alt'],
        to: { name: 'feed' },
        accessRights: 'CAFLA',
        mobile: true,
        desktop: true,
        home: true
    },
    {
        title: 'menu.expeditions',
        icon: ['fad', 'truck-loading'],
        to: { name: 'expeditions' },
        accessRights: 'DMNEXP',
        mobile: true,
        desktop: true,
        home: true
    }
]
