export default [
    {
        title: 'compte.mon_compte',
        icon: ['fal', 'user-cog'],
        to: { name: 'displaySettings' },
        accessRights: 'MVP',
        mobile: true,
        desktop: true,
        home: true
    }
]