import Mvp from './mvp'
import Actes from './actes'
import Invoice from './invoice'
import Monte from './monte'
import Perfs from './perfs'
import Settings from './settings'

let vm = null

export default {
	setInstance(v) {
		vm = v
	},
    all() {
        return []
        .concat(Mvp)
        .concat(Actes)
        .concat(Monte)
        .concat(Invoice)
        .concat(Perfs)
        .concat(Settings)
    },
    user() {
        const menus = this.all()
        this.removeUnauthorizedMenus(menus)
        this.removeDuplicatas(menus)
        return menus
    },
    removeUnauthorizedMenus(menus) {
        let removed = 0
		const menusLen = menus.length

        for (let idx = 0; idx < menusLen; idx++) {
            let menu = menus[idx - removed]

			if(menu.accessRights
                && !vm.checkUserAccessRights(menu.accessRights)
            ) {
                menus.splice(idx - removed, 1)
                removed++
            }
            else if (menu.children) {
                this.removeUnauthorizedMenus(menu.children)
                
                // Si tous les children sont inaccessibles
                if(menu.children.length === 0) {
                    menus.splice(idx - removed, 1)
                    removed++
                }
            }
        }
    },
    /* Remove some duplicated modules menus (ex. notes & feed) */
    removeDuplicatas(menus) {
        const duplicated = {
            'horseListe': 'effectif',
            'notes': 'feed'
        }

        let removed = 0
		const menusLen = menus.length

        for (let idx = 0; idx < menusLen; idx++) {
            const menuRoute = menus[idx - removed].to
            
            // Si le menu n'a que des children
            if(!menuRoute) continue
            
            const hasCandidate = duplicated[menuRoute.name]
            if(!hasCandidate) continue

            // Si le candidat existe bien dans les menus
            const exists = menus.find(m => {
                if(!m.to) return false
                return m.to.name == hasCandidate
            })

            if(exists) {
                menus.splice(idx - removed, 1)
                removed++
            }
        }
    }
}
