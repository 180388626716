<template>
    <div class="d-flex flex-column" id="main_menu_nav">
        <div class="row no-gutters align-items-center d-none d-md-flex">
            <div class="col col-logo">
                <groomy-logo />
            </div>
            <div class="col-auto col-menu" @click="changeMenuSize">
                <i v-if="isOpenMenu" v-b-tooltip.hover.right title="Réduire le menu"><font-awesome-icon :icon="['fal', 'outdent']" class="toggle_menu" /></i>
                <i v-else v-b-tooltip.hover.right title="Agrandir le menu"><font-awesome-icon :icon="['fal', 'indent']" class="toggle_menu" /></i>
            </div>
        </div>
        <div class="row no-gutters align-items-center mb-4 mt-4 search">
            <div class="col-auto d-none">
                <a href=""><i :disabled="hide_tooltip" v-b-tooltip.right title="Rechercher"><font-awesome-icon :icon="['fal', 'search']"/></i></a>
            </div>
            <div class="col d-none">
                <input type="text" placeholder="Rechercher...">
            </div>
        </div>
        <ul class="sub_menu h-100" :class="{ 'd-flex flex-column pb-5' : isNavOpen }" v-show="!isBelowMd || isNavOpen">
            <h2 class="d-md-none">{{ $t('header.menu') }}</h2>
            <groomy-logo class="d-sm-none"/>
            <li v-for="menu in menus" :key="menu.title" :class="`menu_${menu.to ? menu.to.name : 'par'}`">
                <div
                    :is="menu.to ? 'router-link' : 'a'"
                    :to="menu.to"
                    href="#"
                    @click.prevent
                    active-class="menuActive"
                    v-b-toggle="`collapse-${menu.title}`"
                >
                    <i
                        :disabled="hide_tooltip"
                        v-b-tooltip.right
                        :title="$t(menu.title)"
                    >
                        <font-awesome-icon :icon="menu.icon" />
                    </i>
                    {{ $t(menu.title) }} 
                    <i v-if="menu.children" class="fal fa-angle-down">
                        <font-awesome-icon :icon="['fal', 'angle-down']"/>
                    </i>
                </div>

                <b-collapse
                    v-if="menu.children"
                    accordion="accordionMenu"
                    tag="ul"
                    class="submenu"
                    :id="`collapse-${menu.title}`"
                >
                    <li v-for="child in menu.children" :key="child.title">
                        <router-link
                            active-class="menuActive"
                            :to="child.to"
                        >
                            <i v-b-tooltip.right :title="$t(child.title)">
                                <font-awesome-icon :icon="child.icon" />
                            </i> {{ $t(child.title) }}
                        </router-link>
                    </li>
                </b-collapse>
            </li>

            <li v-if="isThroughCa && isBelowMd" class="mt-auto">
                <a href="#" v-on:click.prevent="logout">
                    <i v-b-tooltip.right title="Déconnexion">
                        <font-awesome-icon :icon="['fal', 'door-open']" />
                    </i> {{ $t('header.logout') }}
                </a>
            </li>
        </ul>

        <NavFooter v-show="!isBelowMd || isNavFooterOpen" />
    </div>
</template>

<script type="text/javascript">
import Constants from 'Constants'
import Navigation from '@/mixins/Navigation.js'
import Window from '@/mixins/Window.js'
import Menus from '@/assets/js/menus'

export default {
	mixins: [Navigation, Window],
	components: {
		NavFooter: () => import('GroomyRoot/components/Header/NavFooter'),
	},
    data() {
        return {
			menus: [],
            isOpenMenu: true,
            events_tab: {
                'User::AccessRightsReloaded': this.reloadMenus
            }
        }
    },
    watch: {
        $route (to) {
            // A chaque changement de route, on applique la bonne classe de resize
            this.handleResize()
            this.$store.commit('nav/close')
        }
    },
    computed: {
        hide_tooltip() {
            return this.isOpenMenu
        },
        isNavOpen() {
            return this.$store.state.nav.isOpen
        },
        isNavFooterOpen() {
            return this.$store.state.nav.isFooterOpen
        },
        isThroughCa() {
            return Constants.APP_CA_STANDARDS === true
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize)
		window.addEventListener('load', this.handleResize)
		Menus.setInstance(this)
        this.reloadMenus()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
        window.removeEventListener('load', this.handleResize)
    },
    methods: {
        reloadMenus() {
            const menus = Menus.user()
			this.menus = menus.filter(menu => menu.desktop)
        },

        handleResize(){
            if(document.querySelector('nav') && document.querySelector('main'))
            {
                if(window.innerWidth < 992 && this.isOpenMenu)
                {
                    this.changeMenuSize()
                }
            }
        },

        changeMenuSize() {
            this.isOpenMenu = !this.isOpenMenu
            this.updateUserMenuConfig('menu_open', this.isOpenMenu)
            this.resizeMenu()
        },

        resizeMenu() {
            if(!document.querySelector('nav') || !document.querySelector('main')) {
                return
            }

            // S'il est déjà en taille normale, on le réduit au click
            if(this.isOpenMenu) {
                document.querySelector('body').classList.remove('min')

                document.querySelector('.toggle_menu').classList.add('fa-outdent')
                document.querySelector('.toggle_menu').classList.remove('fa-indent')
            }
            else {
                document.querySelector('body').classList.add('min')

                document.querySelector('.toggle_menu').classList.remove('fa-outdent')
                document.querySelector('.toggle_menu').classList.add('fa-indent')
            }
        },

        async logout() {
            await this.delogUser()
            this.$store.commit('nav/closeFooter')
        },
    }
}
</script>
